import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import HeroLego from "legos/HeroLego"
import theme from "theme"

const ContactPage = ({
  data: {
    settings: { hero },
  },
}) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/embed/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7528527",
          formId: "73c86a25-200b-4cfc-a30a-fa1c3c5fd69d",
          target: "#hubspotForm",
        })
      }
    })

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout title="Contact" css={{ background: theme.gray1 }}>
      <HeroLego {...hero} />

      <div
        css={{
          background: theme.gray1,
          position: "relative",
          zIndex: 2,
          paddingBottom: 30,
        }}
      >
        <div
          css={{
            maxWidth: 560,
            margin: "0 auto",
            padding: "110px 30px",
            [theme.mobile]: { padding: "40px 30px" },
          }}
        >
          <div id="hubspotForm"></div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

ContactPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.shape({
      hero: PropTypes.shape(HeroLego.propTypes).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  {
    settings: sanityContactSettings {
      hero {
        ...HeroLego
      }
    }
  }
`
